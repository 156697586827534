import { render, staticRenderFns } from "./AppMenuItem.vue?vue&type=template&id=d33eac0c&scoped=true&"
import script from "./AppMenuItem.vue?vue&type=script&lang=js&"
export * from "./AppMenuItem.vue?vue&type=script&lang=js&"
import style0 from "./AppMenuItem.vue?vue&type=style&index=0&id=d33eac0c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d33eac0c",
  null
  
)

export default component.exports